import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSearch, faTools, faCircle, faCheckCircle, faTimesCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { countryFlags } from '/src/components/support.js'

const NatverkPage = () => {

    var statusIcons = {
        missing: <FontAwesomeIcon icon={faQuestionCircle} style={{color:'#999'}} />,
        investigating: <FontAwesomeIcon icon={faSearch} style={{color:'#999'}} />,
        developing: <FontAwesomeIcon icon={faTools} style={{color:'#999'}} />,
        partly: <FontAwesomeIcon icon={faCircle} style={{color:'#4ea2bc'}} />,
        exists: <FontAwesomeIcon icon={faCheckCircle} style={{color:'rgb(12, 129, 18)'}} />,
        unavailable: <FontAwesomeIcon icon={faTimesCircle} style={{color:'#ff6666'}} />
    }

    var networksStatus = [
        {
            name: 'Allego',
            country: ['SE'],
            link: 'https://www.allego.eu/sv-se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'CaCharge',
            country: ['SE'],
            link: 'https://cacharge.com/',
            status_stations: 'unavailable',
            status_price: 'unavailable',
            status_status: 'unavailable',
            status_chargenow: 'unavailable',
            source: '-',
            comment: 'Finns inte i Laddify med anledning av deras svar "nästan endast privata anläggningar och dessa kan man inte se om man inte är tillagd som användare".',
        },
        {
            name: 'ReCharge',
            country: ['SE'],
            link: 'https://rechargeinfra.com/sv/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Circle K',
            country: ['SE'],
            link: 'https://www.circlek.se/ladda-elbil',
            status_stations: 'missing',
            status_price: 'missing',
            status_status: 'missing',
            status_chargenow: 'missing',
            source: '-',
            comment: 'XXX',
        },
        {
            name: 'Clever',
            country: ['SE', 'NO', 'DK'],
            link: 'https://clever.dk',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'EVcore',
            country: ['SE'],
            link: 'https://www.evcore.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Dialog pågår.',
        },
        {
            name: 'Eways',
            country: ['SE'],
            link: 'https://www.eways.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'InCharge',
            country: ['SE'],
            link: 'https://incharge.vattenfall.se',
            status_stations: 'exists',
            status_price: 'unavailable',
            status_status: 'unavailable',
            status_chargenow: 'unavailable',
            source: 'Nobil.no',
            comment: 'Svarar att de inte kan tillhandahålla information utan hänvisar till Nobil.no vilket används men saknar fullständig information. Godkänt att visa logga.',
        },
        {
            name: 'Ionity',
            country: ['SE'],
            link: 'https://ionity.eu',
            status_stations: 'developing',
            status_price: 'developing',
            status_status: 'developing',
            status_chargenow: 'developing',
            source: '-',
            comment: 'Avtalsskrivning pågår.',
        },
        {
            name: 'Mer',
            country: ['SE'],
            link: 'https://se.mer.eco',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Park & Charge',
            country: ['SE'],
            link: 'https://park-charge.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'Tesla Supercharger (SuC)',
            country: ['SE'],
            link: 'https://www.tesla.com/sv_se/supercharger',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 24/6.',
        },
        {
            name: 'Tesla destinationsladdare',
            country: ['SE'],
            link: 'https://www.tesla.com/sv_se/destination-charging',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 24/6.',
        },
        {
            name: 'Eon',
            country: ['SE'],
            link: 'https://www.eon.se/elbil/ladda',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'Virta',
            country: ['SE'],
            link: 'https://www.virta.global/sv/elbilsanvandare',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 24/6.',
        },
        {
            name: 'OKQ8',
            country: ['SE'],
            link: 'https://www.okq8.se/elbilsladdning/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Lidl',
            country: ['SE'],
            link: 'https://www.lidl.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 12/6.',
        },
        {
            name: 'kWatt',
            country: ['SE'],
            link: 'https://www.kwatt.se/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Göteborgs energi',
            country: ['SE'],
            link: 'https://www.goteborgenergi.se/privat/ladda-elbil',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Milepost',
            country: ['SE'],
            link: 'https://www.milepost.se/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'ChargeNode',
            country: ['SE'],
            link: 'https://chargenode.eu',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 15/6.',
        },
        {
            name: 'Reload',
            country: ['SE'],
            link: 'https://www.myreload.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 8/6.',
        },
        {
            name: 'Qwello',
            country: ['SE'],
            link: 'https://qwello.se/sv',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 12/6.',
        },
        {
            name: 'Eviny',
            country: ['SE', 'NO'],
            link: 'https://eviny.no',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 19/6.',
        },
        {
            name: 'Pay N Charge',
            country: ['SE'],
            link: 'https://payncharge.se',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Dialog pågår.',
        },
        {
            name: 'Charge Heroes',
            country: ['SE'],
            link: 'https://www.chargeheroes.com',
            status_stations: 'unavailable',
            status_price: 'unavailable',
            status_status: 'unavailable',
            status_chargenow: 'unavailable',
            source: '-',
            comment: 'Tackat nej just nu, fokuserar på sin lansering.',
        },
        {
            name: 'Övrigt',
            country: ['SE', 'NO'],
            link: null,
            status_stations: 'exists',
            status_price: 'partly',
            status_status: 'partly',
            status_chargenow: 'partly',
            source: 'Flera',
            comment: 'Bland annat från Nobil.no',
        },
        {
            name: 'Laddkoll',
            country: ['SE'],
            link: 'https://www.laddkoll.se/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Tillfrågad 24/6.',
        },
        {
            name: 'Monta',
            country: ['SE', 'DK'],
            link: 'https://monta.com/se/',
            status_stations: 'investigating',
            status_price: 'investigating',
            status_status: 'investigating',
            status_chargenow: 'investigating',
            source: '-',
            comment: 'Dialog pågår.',
        },
        /*
        {
            name: 'XXX',
            country: ['SE'],
            link: 'XXX',
            status_stations: 'missing',
            status_price: 'missing',
            status_status: 'missing',
            status_chargenow: 'missing',
            source: '-',
            comment: 'XXX',
        },
        */
    ]
    networksStatus.sort((a, b) => a.name.localeCompare(b.name));


    var networksAliases = [
        {
            name: 'Preem',
            country: ['SE'],
            link: 'https://www.preem.se/privat/drivmedel/laddstationer-elbilar/',
            aliasName: 'ReCharge'
        },
        {
            name: 'Vattenfall',
            country: ['SE'],
            link: null,
            aliasName: 'InCharge'
        },
        {
            name: 'Opigo',
            country: ['SE'],
            link: null,
            aliasName: 'Evcore'
        },
        /*
        {
            name: 'XXX',
            country: ['SE'],
            link: 'XXX',
            aliasName: 'XXX'
        },
        */
    ]
    networksAliases.sort((a, b) => a.name.localeCompare(b.name));


    return (
        <Layout pageTitle="Nätverk">
            
            <p>Målsättningen är att så många laddnätverk som möjligt inom Sverige (till en början) ska finnas med i Laddify. Möjligheterna att hämta information från de olika laddnätverken ser olika ut och vilken typ av information som visas i Laddify kan därför variera. <Link to="/om/forslag">Hör gärna</Link> av dig om du saknar något nätverk i listan! Tabellen nedan uppdateras löpande med aktuell status.</p>

            <h2>Aktuell status</h2>
            <table style={{textAlign: 'center', width: '100%'}}>
                <tr>
                    <td></td>
                    <td style={{textAlign:'left'}}>Nätverk/källa:</td>
                    <td>Stationer &amp; uttag:</td>
                    <td>Priser &amp; betalmetoder:</td>
                    <td>Status uttag:</td>
                    <td>Ladda via Laddify:</td>
                    <td>Källa:</td>
                    <td style={{textAlign:'left'}}>Kommentar:</td>
                </tr>
                {
                    networksStatus && networksStatus.map((item) => {
                        return(
                            <tr>
                                <td>{
                                    item.country && item.country.map((item2) => {
                                        return(countryFlags[item2]+' ')
                                    })
                                }</td>
                                <td style={{textAlign:'left'}}>
                                    {item.name} <a href={item.link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a>
                                </td>
                                <td>{statusIcons[item.status_stations]}</td>
                                <td>{statusIcons[item.status_price]}</td>
                                <td>{statusIcons[item.status_status]}</td>
                                <td>{statusIcons[item.status_chargenow]}</td>
                                <td>{item.source}</td>
                                <td style={{textAlign:'left', fontSize:'12px'}}>{item.comment}</td>
                            </tr>
                        )
                    })
                }
            </table>
            <br/>

            <h2>Kolumnbeskrivning:</h2>
            <ul>
                <li>Flagga: För vilka länder som nätverkets stationer visas i Laddify.</li>
                <li>Nätverk: Namn på nätverket och länk till deras webbsida. Kan ibland även vara en källa/samlingsdatabas.</li>
                <li>Stationer: Om nätverkets stationer och dess uttag visas i Laddify.</li>
                <li>Priser: Om nätverkets priser för de aktuella uttagen på stationerna visas i Laddify.</li>
                <li>Status: Om uttagets aktuella status visas i Laddify.</li>
                <li>Ladda via Laddify: Om det går att via Laddify klicka sig vidare för att starta laddning på det aktuell uttaget.</li>
                <li>Källa: Var Laddify hämtar informationen ifrån (anges endast "API" är det nätverkets egna API som avses).</li>
                <li>Kommentar: Mer information om statusarna i föregående kolumner.</li>
            </ul>

            <h2>Ikonbeskrivning:</h2>
            <p>
                {statusIcons['missing']} <span style={{fontSize:'13px'}}>Information <b>saknas</b> just nu i Laddify men nätverket är identifierat som intressant av Laddify eller från användare.</span>
            </p>
            <p>
                {statusIcons['investigating']} <span style={{fontSize:'13px'}}>Information <b>saknas</b> just nu i Laddify men aktiv undersökning pågår. För att nätverkets stationer ska kunna visas behöver information tillhandahållas på ett strukturerat sätt (API:er) och nätverket behöver samtycka till att informationen samlas in och visas.</span>
            </p>
            <p>
                {statusIcons['developing']} <span style={{fontSize:'13px'}}>Information <b>saknas</b> just nu i Laddify men aktiv utveckling pågår och informationen borde snart finnas i Laddify.</span>
            </p>
            <p>
                {statusIcons['partly']} <span style={{fontSize:'13px'}}>Information finns <b>delvis</b> i Laddify men har begräsningar, se kommentar.</span>
            </p>
            <p>
                {statusIcons['exists']} <span style={{fontSize:'13px'}}>Information <b>finns</b> i Laddify och uppdateras automatiskt minst en gång per dygn.</span>
            </p>
            <p>
                {statusIcons['unavailable']} <span style={{fontSize:'13px'}}>Information <b>saknas</b> i Laddify vilket vanligtvis beror på att nätverket inte tillhandahåller informationen på ett strukturerat sätt eller att de inte samtycker till att informationen samlas in och visas i Laddify.</span>
            </p>


            <h2>Saknar du något nätverk?</h2>
            <table>
                <tr>
                    <td></td>
                    <td>Letar efter?</td>
                    <td>Se istället:</td>
                </tr>
                {
                    networksAliases && networksAliases.map((item) => {
                        return(
                            <tr>
                                <td>{
                                    item.country && item.country.map((item2) => {
                                        return(countryFlags[item2]+' ')
                                    })
                                }</td>
                                <td style={{textAlign:'left'}}>
                                    {item.name} <a href={item.link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a>
                                </td>
                                <td>{item.aliasName}</td>
                            </tr>
                        )
                    })
                }
            </table>
            <p><i>Annars <Link to="/om/forslag">tipsa</Link> gärna Laddify!</i></p>

            <h2>Är du ett nätverk?</h2>
            <p>Läs mer <Link to="/om/for-natverk">här</Link>.</p>

        </Layout>
    )
}

export default NatverkPage